@font-face {
  font-family: 'Satoshi-Variable';
  src: url('./assets/fonts/satoshi/Satoshi-Variable.woff2') format('woff2'),
    url('./assets/fonts/satoshi/Satoshi-Variable.woff') format('woff'),
    url('./assets/fonts/satoshi/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Light';
  src: url('./assets/fonts/satoshi/Satoshi-Light.woff2') format('woff2'),
    url('./assets/fonts/satoshi/Satoshi-Light.woff') format('woff'),
    url('./assets/fonts/satoshi/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Regular';
  src: url('./assets/fonts/satoshi/Satoshi-Regular.woff2') format('woff2'),
    url('./assets/fonts/satoshi/Satoshi-Regular.woff') format('woff'),
    url('./assets/fonts/satoshi/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Medium';
  src: url('./assets/fonts/satoshi/Satoshi-Medium.woff2') format('woff2'),
    url('./assets/fonts/satoshi/Satoshi-Medium.woff') format('woff'),
    url('./assets/fonts/satoshi/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Bold';
  src: url('./assets/fonts/satoshi/Satoshi-Bold.woff2') format('woff2'),
    url('./assets/fonts/satoshi/Satoshi-Bold.woff') format('woff'),
    url('./assets/fonts/satoshi/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Black';
  src: url('./assets/fonts/satoshi/Satoshi-Black.woff2') format('woff2'),
    url('./assets/fonts/satoshi/Satoshi-Black.woff') format('woff'),
    url('./assets/fonts/satoshi/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient-Variable';
  src: url('./assets/fonts/sentient/Sentient-Variable.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-Variable.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient-VariableItalic';
  src: url('./assets/fonts/sentient/Sentient-VariableItalic.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-VariableItalic.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-VariableItalic.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Sentient-Extralight';
  src: url('./assets/fonts/sentient/Sentient-Extralight.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-Extralight.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient-ExtralightItalic';
  src: url('./assets/fonts/sentient/Sentient-ExtralightItalic.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-ExtralightItalic.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Sentient-Light';
  src: url('./assets/fonts/sentient/Sentient-Light.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-Light.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient-LightItalic';
  src: url('./assets/fonts/sentient/Sentient-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-LightItalic.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Sentient-Regular';
  src: url('./assets/fonts/sentient/Sentient-Regular.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-Regular.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient-Italic';
  src: url('./assets/fonts/sentient/Sentient-Italic.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-Italic.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Sentient-Medium';
  src: url('./assets/fonts/sentient/Sentient-Medium.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-Medium.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient-MediumItalic';
  src: url('./assets/fonts/sentient/Sentient-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-MediumItalic.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Sentient-Bold';
  src: url('./assets/fonts/sentient/Sentient-Bold.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-Bold.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient-BoldItalic';
  src: url('./assets/fonts/sentient/Sentient-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/sentient/Sentient-BoldItalic.woff') format('woff'),
    url('./assets/fonts/sentient/Sentient-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Manrope-Variable';
  src: url('./assets/fonts/manrope/Manrope-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 200 300 400 500 600 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Erode-Variable';
  src: url('./assets/fonts/erode/Erode-Variable.ttf') format('truetype');
  font-weight: 100 200 300 400 500 600 700;
  font-display: swap;
  font-style: normal;
}

body {
  font-family: 'Manrope-Variable';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ck-content > blockquote,
.ck-content > dl,
.ck-content > dd,
.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6,
.ck-content > hr,
.ck-content > figure,
.ck-content > p,
.ck-content > pre {
  margin: revert;
}

.ck-content > ol,
.ck-content > ul {
  list-style: revert;
  margin: revert;
  padding: revert;
}

.ck-content > table {
  border-collapse: revert;
}

.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6 {
  font-size: revert;
  font-weight: revert;
}
